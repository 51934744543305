import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { Card } from '@tootz/react-admin/components'

import usePrice from '@/hooks/usePrice'
import { useResourceRecordsContext } from '@/packages/@tootz/react-admin'

import {
  calcCurrentSegment,
  calcFinishedPercent,
  calcNumberOfFinishedPages
} from './utils/tracking'

const DigitalProductCard = ({
  id,
  slug,
  name,
  image,
  short_description,
  prices,
  segments,
  tracking,
  my,
  className = ''
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    config: { basePath }
  } = useResourceRecordsContext()
  const started = calcNumberOfFinishedPages({ tracking }) > 0
  const finishedPercent = calcFinishedPercent({
    segments,
    tracking
  })
  const currentSegment = calcCurrentSegment({ segments, tracking })

  const handleClick = e =>
    router.push(
      my
        ? `/my-digital-products/${slug || id}/learn`
        : `${basePath}/${slug || id}`
    )
  const price = usePrice(prices)

  return (
    <Card className={`${className}`} onClick={handleClick}>
      <div className="position-relative">
        <img
          src={_.get(image, 'file.url')}
          className="w-100 d-block obj-fit-cover obj-position-center h-175px"
          style={{ borderRadius: '1rem 1rem 0 0' }}
          alt=""
        />
        <img
          src="/misc/frame-card-thumbnail.png"
          className="w-100 position-absolute bottom-0 d-block"
          alt=""
        />
      </div>
      <Card.Body className="d-flex flex-column p-6">
        <div className="fs-4 fw-bolder text-dark min-h-60px mb-4">{name}</div>
        {my ? (
          <div className="d-flex align-items-center mb-5">
            <ProgressBar
              now={finishedPercent}
              bg="white"
              className="h-8px flex-grow-1 me-2"
              variant="info"
            />
            <span className="text-info fw-bold fs-8">
              {finishedPercent.toFixed(0)}%
            </span>
          </div>
        ) : (
          <span className="text-gray-600 fw-medium fs-7 mt-1 mb-3 d-block h-90px truncate-text overflow-hidden">
            {short_description}
          </span>
        )}
        {/* <div className="d-flex align-items-center mb-6">
          {_.range(1, 6).map(index => (
            <i
              key={`star-${index}`}
              className="fas fa-star text-warning me-1"
            ></i>
          ))}
        </div> */}
        {my ? (
          <span className="btn btn-primary btn-block w-100">
            {started
              ? t('buttons.continueLearning')
              : t('buttons.startLearning')}
          </span>
        ) : (
          <div className="d-flex justify-content-between align-items-center gap-2 mt-auto">
            {price && (
              <span className="text-info fs-3 fw-bolder d-block">
                {price &&
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: price.coin
                  }).format(price.cents / 100)}
                {/* valor com desconto */}
                {/* {price && (
            <span className="text-info fs-3 fw-bolder d-block">
              {price &&
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: price.coin
                }).format(
                  (price.cents * (1 - _.get(price, 'cash_discount', 0) / 100)) /
                    100
                )} */}
              </span>
            )}
            {/* <span className="text-light-info fs-8 fw-bold">
            {t('settings.inCash')}
          </span> */}
            <span className="btn btn-primary btn-sm ms-auto">
              {t('details')}
            </span>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default DigitalProductCard
