import useLocaleCoin from './useLocaleCoin'

const usePrice = prices => {
  const currentCoin = useLocaleCoin()

  if (!prices || prices.length == 0) return null

  return prices?.find(price => price.coin == currentCoin) || prices[0]
}

export default usePrice
