
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { Toolbar } from '@/components/Toolbar'
import DigitalProductsList from '@/domain/Resources/DigitalProducts/List'
import {
  PAID_INVOICES_CONFIG,
  NOT_PAID_INVOICES_CONFIG
} from '@/domain/Resources/Invoices/config'
import { InvoicesList } from '@/domain/Resources/Invoices/List'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Toolbar />
      <div className="container">
        <div className="mx-2 mx-lg-6 d-flex flex-column gap-12">
          <InvoicesList.Root
            {...{ ...NOT_PAID_INVOICES_CONFIG, pagination: { per_page: 3 } }}
          >
            <InvoicesList.Title text={t('upcomingCharges')} />
            <InvoicesList.Items pagination={{ perPage: 3 }}>
              <Link href={`/financial/pending`}>
                <div className="btn btn-link btn-sm btn-color-info btn-active-color-info py-0">
                  {t('seeAllUpcomingCharges')}
                </div>
              </Link>
            </InvoicesList.Items>
          </InvoicesList.Root>
          <InvoicesList.Root
            {...{ ...PAID_INVOICES_CONFIG, pagination: { per_page: 3 } }}
          >
            <InvoicesList.Title text={t('lastPaidCharges')} />
            <InvoicesList.Items pagination={{ perPage: 3 }}>
              <Link href={`/financial/paid`}>
                <div className="btn btn-link btn-sm btn-color-info btn-active-color-info py-0">
                  {t('seeAllPaidCharges')}
                </div>
              </Link>
            </InvoicesList.Items>
          </InvoicesList.Root>
          <DigitalProductsList />
        </div>
      </div>
    </>
  )
}

export default HomePage


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  