import _ from 'lodash'

const calcNumberOfPages = ({ segments }) =>
  segments.reduce(
    (numberOfPages, segment) =>
      numberOfPages +
      (_.has(segment, 'pages_size')
        ? segment.pages_size
        : _.get(segment, 'pages', []).length),
    0
  )

const calcNumberOfFinishedPages = ({ tracking }) =>
  _.get(tracking, 'tracking.finished_pages', []).length

const calcFinishedPercent = ({
  segments,
  tracking,
  numberOfPages: _numberOfPages,
  numberOfFinishedPages: _numberOfFinishedPages
}) => {
  let numberOfPages = _numberOfPages
  let numberOfFinishedPages = _numberOfFinishedPages

  if (segments) numberOfPages = calcNumberOfPages({ segments })
  if (tracking) numberOfFinishedPages = calcNumberOfFinishedPages({ tracking })

  if (numberOfPages == 0) return 0

  return Math.round((numberOfFinishedPages / numberOfPages) * 100)
}

const calcCurrentSegment = ({ tracking, segments }) => {
  if (!tracking) return 0

  const {
    meta: { current_segment_id }
  } = tracking

  const currentSegment = segments.find(
    segment => (segment.id = current_segment_id)
  )

  return currentSegment
}

export {
  calcNumberOfPages,
  calcNumberOfFinishedPages,
  calcFinishedPercent,
  calcCurrentSegment
}
