import useTranslation from 'next-translate/useTranslation'

import {
  useResourceRecordsContext,
  withResourceRecords,
  useRecords
} from '@tootz/react-admin'
import { LoadWrapper } from '@tootz/react-admin/components'

import { Carousel } from '@/components/Carousel'

import DigitalProductCard from './Card'
import useConfig from './config'

const DigitalProductsList = () => {
  const { t } = useTranslation()
  const {
    records: digitalProducts,
    handleDelete,
    params,
    loadWrapperConfig
  } = useResourceRecordsContext()
  const { records: myDigitalProducts, loadWrapperConfig: loadWrapperConfigMy } =
    useRecords({
      r: '/client/digital_products/my',
      queryParams: params
    })

  const digitalProductsWithoutMy = _.filter(
    digitalProducts,
    dp => !_.includes(_.map(myDigitalProducts, 'id'), dp.id)
  )

  return (
    <>
      {myDigitalProducts && myDigitalProducts.length > 0 && (
        <div>
          <span className="fw-bolder fs-2 d-block mb-5">
            {t('my-digital-products')}
          </span>
          <LoadWrapper
            {...loadWrapperConfigMy}
            cardsPerRow={4}
            itemHeight={400}
          >
            <Carousel options={{ navigation: false }}>
              {myDigitalProducts &&
                myDigitalProducts.map(digitalProduct => (
                  <DigitalProductCard
                    key={digitalProduct.id}
                    {...digitalProduct}
                    onDelete={handleDelete}
                    my
                    className="h-100"
                  />
                ))}
            </Carousel>
          </LoadWrapper>
        </div>
      )}
      <div>
        <span className="fw-bolder fs-2 d-block mb-5">
          {t('discover-other-digital-products')}
        </span>
        <LoadWrapper {...loadWrapperConfig} cardsPerRow={4} itemHeight={425}>
          {digitalProducts && digitalProductsWithoutMy.length > 1 && (
            <Carousel>
              {digitalProductsWithoutMy.map(digitalProduct => (
                <DigitalProductCard
                  key={digitalProduct.id}
                  {...digitalProduct}
                  onDelete={handleDelete}
                  className="h-100"
                />
              ))}
            </Carousel>
          )}
        </LoadWrapper>
      </div>
    </>
  )
}

export default withResourceRecords(DigitalProductsList, useConfig)
