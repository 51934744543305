import React, { useEffect, useRef } from 'react'

import { register } from 'swiper/element/bundle'

register()

// import 'swiper/css'
// import 'swiper/css/navigation'
// import 'swiper/css/pagination'
// import 'swiper/css/scrollbar'

export const Carousel = ({
  navigationStyle = 'light',
  options = {},
  children
}) => {
  const swiperElRef = useRef(null)
  const swiperParams = {
    slidesPerView: 1,
    navigation: {
      enabled: false
    },
    pagination: {
      enabled: true,
      clickable: true,
      dynamicBullets: true
    },
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 2,
        navigation: {
          enabled: true
        }
      },
      1024: {
        slidesPerView: 3,
        navigation: {
          enabled: true
        }
      },
      1280: {
        slidesPerView: 4,
        navigation: {
          enabled: true
        }
      }
    },
    injectStyles: [
      `
      :host {
        --swiper-theme-color: #ff5959;
        --swiper-navigation-sides-offset: 0px;
        --swiper-pagination-bottom: 0px;
      }

      :host .swiper-button-prev,
      :host .swiper-button-next {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: ${navigationStyle == 'light' ? '#fff' : '#ff5959'};
        color: ${navigationStyle == 'light' ? '#ff5959' : '#fff'};
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :host .swiper-button-prev svg,
      :host .swiper-button-next svg  {
        color: ${navigationStyle == 'light' ? '#ff5959' : '#fff'};
        width: 24px;
        height: 24px;
      }
      `
    ],
    on: {
      init() {}
    },
    ...options
  }

  useEffect(() => {
    Object.assign(swiperElRef.current, swiperParams)

    swiperElRef.current.initialize()
  }, [])

  return (
    <swiper-container ref={swiperElRef} init="false">
      {React.Children.map(children, child => (
        <swiper-slide key={child.key}>
          <div className="pb-6 h-100 d-flex flex-column">
            {React.cloneElement(child, child.props)}
          </div>
        </swiper-slide>
      ))}
    </swiper-container>
  )
}
