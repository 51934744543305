import useTranslation from 'next-translate/useTranslation'

const availableLanguages = [
  { language: 'pt', coin: 'BRL' },
  { language: 'en', coin: 'USD' }
]

const useLocaleCoin = () => {
  const { lang } = useTranslation()

  const language = availableLanguages.find(
    language => language.language == lang
  )

  if (language) return language.coin
  else return availableLanguages[0].coin
}

export default useLocaleCoin
