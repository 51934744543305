import cc from 'currency-codes'
import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'

import getLanguages from '../../../services/languages'

const allowedCurrencyCodes = ['BRL', 'USD']

const useConfig = props => {
  const { id, my } = props
  const { t } = useTranslation()

  const basePath = `/${my ? 'my-' : ''}digital-products`
  const kinds = [
    { slug: 'course', name: t('course'), icon: 'clapperboard-play' },
    { slug: 'online_event', name: t('onlineCourse'), icon: 'calendar-star' },
    { slug: 'community', name: t('community'), icon: 'users-rays' },
    { slug: 'ebook', name: t('ebook'), icon: 'notebook' },
    { slug: 'audio', name: t('audio'), icon: 'music' },
    { slug: 'movie', name: t('movie'), icon: 'camera-movie' },
    { slug: 'podcast', name: t('podcast'), icon: 'microphone-lines' },
    { slug: 'image', name: t('image'), icon: 'images' },
    { slug: 'files', name: t('files'), icon: 'file-zipper' }
  ]
  const status = [
    { slug: 'draft', name: t('sketch'), variant: 'light' },
    { slug: 'ready', name: t('waitingPublish'), variant: 'light-info' },
    { slug: 'published', name: t('published'), variant: 'light-success' },
    { slug: 'unpublished', name: t('unpublished'), variant: 'light-danger' }
  ]
  const languages = getLanguages(['pt', 'en', 'es', 'fr', 'it']).map(lang => ({
    slug: lang.code,
    name: lang.native
  }))
  const currencies = allowedCurrencyCodes.map(code => {
    const currency = cc.code(code)

    return {
      ...currency,
      slug: currency.code,
      name: currency.currency
    }
  })
  const navItems = [
    {
      slug: 'start',
      icon: 'home',
      name: t('home'),
      href: id && `${basePath}/${id}`
    },
    {
      slug: 'modules',
      icon: 'folders',
      name: t('configs.content'),
      href: id && `${basePath}/${id}/modules`
    },
    {
      slug: 'users',
      icon: 'users',
      name: t('users'),
      href: id && `${basePath}/${id}/users`
    },
    {
      slug: 'comments',
      icon: 'comments',
      name: t('tabs.comments'),
      href: id && `${basePath}/${id}/comments`
    },
    {
      slug: 'reports',
      icon: 'chart-simple',
      name: t('reports'),
      href: id && `${basePath}/${id}/reports`
    },
    {
      slug: 'config',
      icon: 'cog',
      name: t('settings.title'),
      href: id && `${basePath}/${id}/edit`
    }
  ]

  const config = {
    basePath,
    apiBasePath: `/client/digital_products${my ? '/my' : ''}`,
    icon: 'box-open',
    humanName: {
      singular: t('digitalProduct'),
      plural: t('digitalProducts')
    },
    header: {
      toolbarLinks: [
        {
          text: t('add'),
          url: `${basePath}/new`,
          className: 'btn btn-light-primary btn-sm'
        }
      ]
    },
    pagination: {
      perPage: 999
    },
    navItems,
    getNavView: slug => _.find(navItems, { slug }),
    status,
    getStatus: slug => _.find(status, { slug }),
    kinds,
    getKind: slug => _.find(kinds, { slug }),
    languages,
    getLanguage: slug => _.find(languages, { slug }),
    currencies,
    getCurrency: slug => _.find(currencies, { slug })
  }

  return config
}

export default useConfig
